
export default {
  name: 'ImageWithBoxOnIt',
  props: {
    image: {
      type: Object,
      default: () => ({}),
    },
    imageAlt: {
      type: String,
      default: '/images/hero.jpg',
    },
    content: {
      type: String,
      default: '',
    },
    imageIcon: {
      type: String,
      default: '',
    },
  },
}
