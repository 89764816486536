
export default {
  name: 'SectionsHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    linkExternal: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonLink: {
      type: String,
      default: '',
    },
    buttonTheme: {
      type: String,
      default: 'secondary',
    },
    buttonLinkExternal: {
      type: Boolean,
      default: false,
    },
    contactForm: {
      type: Boolean,
      default: false,
    },
  },
}
