
export default {
  name: 'ImageWithFeatures',
  props: {
    main: {
      type: Object,
      default: () => {},
    },
    features: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: null,
    }
  },
  mounted() {
    this.activeItem = this.selectedItem
  },
}
